import React from "react"
import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import TextLink from "../../components/TextLink"
import Album from '../../components/Album'
import blacKostumePhoto from '../../images/BlacKostume.jpg'
import { blacKostumeAlbums } from '../../data/albums'
import './style.css'

const BlacKostume = () => (
  <Layout>
    <SEO title="BlacKostume" />
    <div className="artist-page">
      <div className="artist-page__bio">
        <div>
          <h1>BlacKostume</h1>
          <h2>Brooklyn, NYC</h2>
        </div>
        <p>Producer, Lyricist, Songwriter from NC.</p>
        <div className="artist-page__bio-links">
          <TextLink href='https://open.spotify.com/artist/73dSUn3RQRuJLCGDD0nEri'>Spotify</TextLink>
          <TextLink href='https://soundcloud.com/blackostume'>Soundcloud</TextLink>
          <TextLink href='https://blackostume.bandcamp.com/'>Bandcamp</TextLink>
          <TextLink href='https://www.instagram.com/blackostume/?hl=en'>Instagram</TextLink>
          <TextLink href='https://twitter.com/blackostume1'>Twitter</TextLink>
        </div>
      </div>
      <div className="artist-page__photo">
        <img src={blacKostumePhoto} alt="BlacKostume" />
      </div>
      <div className="artist-page__albums">
        <h3>Albums</h3>
        <div className="artist-page__albums-list">
          {
            blacKostumeAlbums.map((album, index) => <Album key={index} album={album} />)
          }
        </div>
      </div>
    </div>
  </Layout>
)

export default BlacKostume
